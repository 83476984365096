// src/pages/Analytics.tsx

import React from 'react';

const Analytics: React.FC = () => {
    return (
        <div className="analytics-container">
            <h1>Analytics</h1>
            <div className="iframe-container">
                <iframe
                    src="https://superset.internal.yologamestudios.com/"
                    title="Superset"
                    allowFullScreen
                    className="superset-iframe"
                ></iframe>
            </div>
        </div>
    );
};

export default Analytics;