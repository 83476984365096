// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Analytics from './pages/Analytics';
import Levels from './pages/Levels'; // 'Levels' sayfasını içe aktarıyoruz
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';

function App() {
    return (
        <Router>
            <div>
                <Navbar />
                <Routes>
                    {/* Ana sayfa için route */}
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />

                    {/* Analytics sayfası için route */}
                    <Route
                        path="/analytics"
                        element={
                            <PrivateRoute>
                                <Analytics />
                            </PrivateRoute>
                        }
                    />

                    {/* Levels sayfası için route */}
                    <Route
                        path="/levels"
                        element={
                            <PrivateRoute>
                                <Levels />
                            </PrivateRoute>
                        }
                    />

                    {/* Login sayfası */}
                    <Route path="/login" element={<Login />} />

                    {/* Geçersiz tüm yolları ana sayfaya yönlendirin */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;