// src/services/firebase.ts

import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

// Firebase yapılandırmanız
const firebaseConfig = {
    apiKey: "AIzaSyCnnWqeeh13oltkA3zob9LvHw30EFsYTB4",
    authDomain: "internalyologamestudioscom.firebaseapp.com",
    projectId: "internalyologamestudioscom",
    storageBucket: "internalyologamestudioscom.appspot.com",
    messagingSenderId: "828428472713",
    appId: "1:828428472713:web:ac70aa1a128f9d600d5158",
    measurementId: "G-N4JKJ42HKL"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set authentication persistence to session (cleared when browser is closed)
setPersistence(auth, browserSessionPersistence).catch((error) => {
    console.error('Failed to set persistence:', error);
});

export { auth };
export const analytics = getAnalytics(app);