// src/pages/Levels.tsx

import React from 'react';

const Levels: React.FC = () => {
    return (
        <div className="levels-container">
            <h1>Levels</h1>
            <p>Bam Bam.</p>
        </div>
    );
};

export default Levels;